/* General styling for the sectors container and content */
.sectors-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    align-items: center;
    color: #5271FF;
    background-color: #F4F4F4;
}
.sectors-container hr {
    width: 10%;
    height: 4px;
    color: #5271FF;
    background-color: #5271FF;
    border: none;
}
.sectors-content {
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-template-rows: auto;
    row-gap: 20px;
    column-gap: 60px;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
}
.sectors-content .box {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    position: relative;
}
.sectors-content .box:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}
.icon {
    position: absolute;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 30%;
}
.icon2{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sectors-content .box h3 {
    position: absolute;
    bottom: 0%;
}

/* Styling for the overlay and pop-up */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Box-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    width:50vw ;
    height: 50vh;
    overflow: auto;
    position: relative;
    text-align: center; 
}
.Box-content h2{
    text-align: center;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
}
.Box-content img{
    position: absolute;
    top: 5%;
    left: 3%;
    width: 100px;
}
.Box-content p{
    position: relative;
    background-color: aqua;
    width: 200px;
}
@media screen and (max-width: 768px) {
    .sectors-content {
        grid-template-columns: repeat(2, auto);
    }
    .icon {
        width: 60px;
    }
    .sectors-content .box {
        width: 80px;
        height: 80px;
        font-size: 10px;
    }
}
