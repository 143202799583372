.about-container{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: #5271FF;
}
.about-container hr{
    width: 10%;
    height: 4px;
    color: #5271FF;
    background-color: #5271FF;
    border: none;
}
.about-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-content h1{
    text-align: center;
}
.about-content div{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.about-content p{
    width: 70%;
    padding: 0 15%;
    text-align: justify;
    direction: rtl;
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
}
.about-importance{
    background-color: #EAEAEA;
}
.about-importance h1{
    text-align: center;

}

@media screen and (max-width: 768px) {
    .about-content p{
        font-size: 16px;
    }
}