/* Default styles for the navbar */
.navbar-container {
  width: 90%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #5271ff;
  padding: 0 5%;
  line-height: 5px;
  font-weight: 600;
  z-index: 100;
  direction: rtl;
  font-family:sans-serif;
}

a { text-decoration: none; }

.navbar-container .title h2 {
  color: white;
  font-size: 20px;
  font-weight: 900; 
}

.navbar-container .title span{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  gap: 5px;
}
.navbar-container .title p { 
  font-size: 20px; 
  color: black;
}

.navbar-container .Navlink {
  color: #0B1857;
  font-size: 20px;
}

.navbar-container .link-btns {
  display: flex;
  flex-direction: row;
  width: 25%;
  align-items:center;
  justify-content: end;
  gap: 5px;
}

.navbar-container .link-btns img {
  width: 60px;
}

.navbar-container .link-btns a:hover {
  background-color: aqua;
  color: #0B1857;
  transition: 0.2s ease-in-out; 
}

/* Dropdown menu */
.navbar-container .dropdown {
  position: relative;
  display: inline-block;
}

.navbar-container .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.navbar-container .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar-container .dropdown:hover .dropdown-content {
  display: block;
}

.navbar-container .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.Navlink {
  cursor: pointer;
}
.mobile-nav {
  display: none;
}
.links3{
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}
.links3 *:hover{
  color: #ffffff;
  cursor: pointer;
}
.links3 .Navlink{
  font-size: 20px;
}
@media screen and (min-width: 1800px) {
  .links3{
    font-size: 24px;
  }
  .links3 .Navlink{
    font-size: 24px;
  }
}
/* Mobile menu styles */
@media screen and (max-width: 768px) {
  .navbar-container {
    display: none;
  }

  .mobile-nav {
    display: block;
    position: relative;
    width: 100%;
  }

  .menu-toggle {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    position: fixed; /* Change to fixed */
    top: 10px;
    left: 10px;
    z-index: 3;
    scale: 1.5;
    color: white;
  }

  .menu-toggle2 {
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .side-menu {
    width: 250px;
    background: #333;
    height: 100%;
    position: fixed;
    top: 0;
    left: -250px;
    transition: left 0.3s ease-in-out;
    padding-top: 60px;
    z-index: 100;
  }

  .side-menu a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #fff;
  }

  .side-menu a:hover {
    background: #444;
  }

  .side-menu.open {
    left: 0;
  }
}

