.official-container{
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #5271FF;
    background-color: #F4F4F4;
}
.official-hr{
    width: 10%;
    height: 4px;
    color: #5271FF;
    background-color: #5271FF;
    border: none;
}
.official-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.official-content img{
    width: 40%;
}
@media screen and (max-width: 768px) {
    .official-content img{
        width: 80%;
    }
}