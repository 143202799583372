.parteners-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    color: #5271FF;
}
.parteners-container .container{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.parteners-container .container hr{
    width: 10%;
    height: 4px;
    color: #5271FF;
    background-color: #5271FF;
    border: none;
}
.container .content{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2,auto);
    align-items: center;
    justify-content: center;
    column-gap: 10%;
}
.content img{cursor: pointer;}
    p.LogoName{
    text-align: center;
    font-weight: 600;
    width: 50%;
}
.logo-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.partenersLogo{
    width: 150px;
    border-radius: 30%;
}
@media screen and (max-width:768px) {
    .container .content{
        grid-template-columns: repeat(2,auto);
    }
    .partenersLogo{
        width: 100px;
    }
}