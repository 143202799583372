.footer{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    line-height: 15px;
    color: #5271FF;
    background-color: #1B1B1B;
}
.foot{
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}
.main h2,span{
color: white;
line-height: 0px;
font-weight: 600;
}

.foot p{
    color: gray;
}
.main{
    width: 50%;
    text-align: left;
    text-indent: 5%;
}
.main h2{
    width: 100%;
}
.main p{
    width: 100%;
    text-align: left;
}
.main .title{
    color: #5271FF;
}
.foot a{
    color: gray;
}
.section{
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    direction: rtl;
    line-height: 180%;
}

@media screen and (min-width: 1800px) {
    .footer{
        height: 35vh;
    }
}
@media screen and (max-width: 768px) {
    .footer{
        height: 35vh;
    }
    
    .main p{
        font-size: 12px;
        width: 100%;
        text-align: left;
    }
    .main h2{
        font-size: 16px;
        width: 100%;
    }
    .title{
        font-size: 12px;
        width: 100%;
    }
    
}