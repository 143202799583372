.home-container {
    width: 100%;
    height: 145vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    direction: rtl;
    background-color: #EAEAEA;
}
.img-holder{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img-holder .word1{
    width: 200px;
}
 .img-holder .logo2{
    width: 100px;
}
.img-holder .word2{
    width: 200px;
    scale: 1.5;
}
.home-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../images/gg.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    background-position: center;
    filter: blur(5px) brightness(80%);
    -webkit-filter: blur(5px) brightness(80%);
    z-index: -1;
}

.home-content {
    padding-top: 3%;
    width: 70%;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px; 
}

.home-content h1 {
    color: white;
    line-height: 5px;
    font-size: 40px;
}
.home-content h1 span  {
    color: #000000;
}
.home-content h2{
    color: white;
    font-size: 24px;
}
.tickcounter {
    scale: 0.5;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.home-footer {
    width: 100%; 
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: auto;
    margin-top: auto; 
    height: 180px;
    justify-content: center;
    align-items: center;
    gap:0 50px;
    background-color: #EAEAEA;
}
.home-footer button{
    height: 50px;
    width: 200px;
    background-color: #5271FF;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    font-weight: 600;
}
.home-footer button:hover{
    background-color: #4967eb;
}
.Special{
    margin-top: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Special .specialfont{
    color:rgb(236, 216, 33);
}
@media screen and (min-width: 1800px) {
    .home-container{
        height: 120vh;
    }
}
@media screen and (max-width: 768px) {
    .home-container{
        height: 100vh;
    }
    .home-content h1{
        font-size: 20px;
        margin-bottom:10% ;
    }
    .home-content h2{
        width: 100%;
        text-align: center;
        font-size: 16px;
    }
    .home-content{padding-top:15%;}
    .tickcounter{scale: 1;}
    .home-footer button{
        width: 120px;
    }
    .home-container::before{
    background-size:cover;
    }
    .img-holder .word1{
        width: 100px;
        scale: 1.2;
    }
     .img-holder .logo2{
        width: 50px;
        scale: 1.5;
    }
    .img-holder .word2{
        display: none;
    }
    .img-holder{
        margin-bottom:10% ;
    }
    .Special{
        margin-bottom: 50px;
    }
}
@media screen and (max-width: 600px) {
    .home-content h1{
        font-size: 16px;
    }
    .home-content h2{
        font-size: 14px;
    }
}