.eventLoc-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0;
    color: #5271FF;
    background-color: #F4F4F4;
}
.eventLoc-container hr{
    width: 10%;
    height: 4px;
    color: #5271FF;
    background-color: #5271FF;
    border: none;
}
.map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    height: 100%;
}
.map-container .BARCODE{
    width: 50%;
    height: 50%;
}
.placePhoto{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    direction: rtl;
    position: relative;
    text-align: right;
}

.placePhoto img{
    filter: brightness(0.7) blur(0.5px);
    box-sizing: border-box;
    width: 80%;
    border-radius: 5px;
}
.eventLoc-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 80%;
}
@media screen and (max-width: 768px){
    .eventLoc-content{
        flex-direction: column;
        align-items: center;
    }
    .map-container{
        width: 300px;
    }   
    .placePhoto{
        align-items: center;
        width: 350px;
    }
    .map-container .BARCODE{
        width: 150px;
        height: 150px;
    }
}